<template>
  <b-modal
    :id="modalId"
    size="lg"
    title="New Service"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @show="clearForm"
    @hidden="clearForm"
  >
    <b-form
      class="email-form"
      @submit="handleSubmit"
    >
      <b-form-group
          label="Title *"
          label-cols-md="2"
          label-align="right"
          label-for="title"
      >
        <b-form-input
            id="title"
            v-model="form.title"
            type="text"
        />
      </b-form-group>
      <b-form-group
          label="Url *"
          label-cols-md="2"
          label-align="right"
          label-for="url"
      >
        <b-form-input
            id="url"
            v-model="form.url"
            type="text"
        />
      </b-form-group>
      <b-form-group
          label="Description"
          label-cols-md="2"
          label-align="right"
          label-for="description"
      >
        <b-form-textarea
            id="description"
            v-model="form.description"
        >
        </b-form-textarea>
      </b-form-group>
      <b-form-group
        label="Owner *"
        label-cols-md="2"
        label-align="right"
        label-for="owner"
      >
        <multiselect
          v-model="form.owner"
          :options="employeesOptions"
          searchable
          track-by="value"
          label="text"
        ></multiselect>
      </b-form-group>
      <b-form-group
          label="Owner email"
          label-cols-md="2"
          label-align="right"
          label-for="owner_email"
      >
        <b-form-select
            id="owner_email"
            v-model="form.email"
            :options="emailsOptions"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validateForm } from '@/utils/validation';

export default {
  name: 'EmailModalForm',
  props: [
    'modalId',
    'employeesOptions',
    'employees',
    'initialData'
  ],
  data() {
    return {
      form: {
        title: '',
        url: '',
        description: '',
        email: '',
        owner: { value: null, text: 'Choose Employee' },
      },
      emailsOptions: []
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      if (validateForm({
        title: this.form.title,
        url: this.form.url,
        owner: this.form.owner.value,
      })) {
        this.$emit('onSubmit', this.form);
      }
    },
    clearForm() {
      this.form.title = '';
      this.form.description = '';
      this.form.email = '';
      this.form.owner = { value: null, text: 'Choose Employee' };
    },
    getEmailsOptions() {
      this.form.email = '';
      this.emailsOptions = [];
      let employee = null;
      this.employees.forEach((element) => {
        if (element.uid === this.form.owner.value) {
          employee = element;
          return null;
        }
      });
      if (employee) {
        this.emailsOptions = employee.emails.map((item) => {
          return {value: item.id, text: item.email};
        });
        if (this.emailsOptions.length > 0) {
          this.form.email = this.emailsOptions[0].value;
        }
      }
    }
  },
  watch: {
    'form.owner': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getEmailsOptions();
        }
      },
    }
  }
}
</script>

<style lang="scss">
  .employee-form {
    .custom-control-label {
      vertical-align: middle;
    }
  }
</style>
