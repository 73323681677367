<template>
  <Layout :loading="loading">
    <ServiceModalForm
        :modalId="modalId"
        :modalTitle="modalTitle"
        :initialData="currentRow"
        :employeesOptions="employeesOptions"
        :employees="employees"
        @onSubmit="handleServiceFormSubmit"
    />
    <div class="services table-page">
      <div class="table-page__header">
        <h1 class="title-1">Services</h1>
        <div>
          <b-button
            class="mr-2"
            variant="primary"
            @click="handleExportToCSV"
          >
            Export to CSV
          </b-button>
          <b-button
            variant="success"
            @click="handleAdd"
          >
            Add Service
          </b-button>
        </div>
      </div>
      <b-card class="table-page__container">
        <b-table
          :items="list"
          :fields="fields"
          hover
          sticky-header
          class="app-table"
          :class="{ 'app-table_filter-open': isDropdownOpen }"
        >
          <template #head(title)>
            <div class="app-table__filter-input">
              <b-form-input
                :value="search"
                ref="search"
                type="text"
                placeholder="Title"
                @input="handleSearchChange"
              />
              <i
                v-if="search"
                class="bx bxs-x-circle app-table__filter-clear"
                @mousedown.prevent="handleSearchChange('')"
              />
            </div>
          </template>
          <template #head(service_employees)>
            <template>
              <multiselect
                v-model="employee"
                :options="employees"
                :searchable="true"
                :show-labels="false"
                label="name"
                track-by="uid"
                placeholder="Owner"
                class="app-multiselect app-table__filter-select"
                @open="isDropdownOpen = true"
                @close="isDropdownOpen = false"
              >
                <template #clear>
                  <i
                    v-if="employee"
                    class="bx bxs-x-circle app-table__filter-clear"
                    @mousedown.prevent="employee = null"
                  />
                </template>
              </multiselect>
            </template>
          </template>
          <template #cell(index)="{ index, item }">
            <router-link
              :to="{ name: 'servicesShow', params: { id: item.id } }"
              class="app-table__row-link"
            />
            {{ index + 1 }}
          </template>
          <template #cell(service_employees)="{ value }">
            <div v-if="value">
              <div
                v-for="(item, index) in value"
                :key="index"
              >
                <div v-if="isEmployeeOwner(item) && item.employee">
                  {{ getOwnerName(item) }}
                </div>
              </div>
            </div>
            <div v-else>No owner</div>
          </template>
        </b-table>
        <empty-page v-if="!list.length" />
      </b-card>
      <pagination
        :page="page"
        :limit="pageLimit"
        :totalRecordsCount="totalRows"
        @onPageChange="handlePageChange"
        @onLimitChange="handleLimitChange"
      />
    </div>
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import Layout from '@/router/layouts/main.vue';
import EmptyPage from '@/components/empty-page.vue';
import Pagination from '@/components/pagination.vue';
import ServiceModalForm from './serviceModalForm.vue';
import { convertObjectToFormData } from '@/utils/converters';
import {FORM_MODE} from "../../../utils/constants";

export default {
  name: 'EmailsPage',
  components: {
    Layout,
    EmptyPage,
    Pagination,
    ServiceModalForm
  },
  data() {
    return {
      page: 1,
      search: '',
      pageLimit: 20,
      modalId: 'service-modal',
      modalTitle: 'New Service',
      currentRow: null,
      employee: null,
      isDropdownOpen: false,
      fields: [
        { key: 'index', label: '', thStyle: { minWidth: '70px', width: '70px', maxWidth: '70px' }, tdClass: 'font-weight-bold' },
        { key: 'title', thStyle: { minWidth: '200px', width: '300px', maxWidth: '300px' } },
        { key: 'service_employees', thStyle: { minWidth: '250px', width: '300px', maxWidth: '300px' } },
        { key: 'description', label: 'Description' },
      ]
    }
  },
  mounted() {
    this.$store.dispatch('employees/getEmployees');
    this.$store.dispatch('employees/getActiveEmployees');
    this.$store.dispatch('services/getServices', this.queryParams);
  },
  computed: {
    list() {
      return this.$store.state.services.list;
    },
    loading() {
      return this.$store.state.services.loading;
    },
    totalRows() {
      return this.$store.state.services.totalItems;
    },
    employees() {
      return this.$store.state.employees.list;
    },
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit,
        title: this.search || null,
        owner_uid: this.employee?.uid ?? null
      }
    },
    ...mapGetters('employees', ['employeesOptions']),
  },
  methods: {
    handleExportToCSV() {
      this.$store.dispatch('services/downloadCSVServices', omit(this.queryParams, ['page', 'per_page']));
    },
    openServicePage(item) {
      if (item.id) {
        this.$router.push({ name: 'servicesShow', params: { id: item.id }});
      }
    },
    handleSearchChange: debounce(async function(value) {
      this.search = value;
      this.page = 1;
      this.$refs.search.blur();
      await this.$store.dispatch('services/getServices', this.queryParams);
      this.$refs.search.focus();
    }, 500),
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handlePageChange(page) {
      this.page = page;
      this.$store.dispatch('services/getServices', this.queryParams);
    },
    handleLimitChange(limit) {
      this.page = 1;
      this.pageLimit = limit;
      this.$store.dispatch('services/getServices', this.queryParams);
    },
    handleServiceFormSubmit(data) {
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('services/createService', convertObjectToFormData({
          title: data.title,
          url: data.url,
          description: data.description,
          employee_uid: data.owner.value,
          email_id: data.email,
        })).then(() => {
          this.$store.dispatch('services/getServices', this.queryParams);
        });
      }
      this.$bvModal.hide(this.modalId);
    },
    openService(id) {
      this.$router.push(`/services/${id}`);
    },
    isEmployeeOwner(employee) {
      const roles = employee.roles.map(({ title }) => title.toLowerCase());
      return roles.includes('owner');
    },
    getOwnerName(employee) {
      return `${employee.employee.name} ${ employee.email ? '(' + employee.email.email + ')' : '' }`;
    }
  },
  watch: {
    employee() {
      this.page = 1;
      this.$store.dispatch('services/getServices', this.queryParams);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-table {
  :deep(tbody) {
    tr {
      cursor: pointer;
    }
  }
}
</style>
